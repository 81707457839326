<template>
  <div>
    <overlay
      v-if="routematch"
      :prompt-before-close="promptBeforeClose"
      @close="closeOverlay"
    >
      <template slot="content">
        <router-view />
      </template>
    </overlay>
    <div class="search">
      <search-input
        v-model="search"
        :placeholder="$t('searchPrograms')"
      />
    </div>
    <vue-good-table
      :columns="columns"
      :rows="tablePrograms"
      style-class="vgt-table"
      :sort-options="{ enabled: true, initialSortBy: {field: 'admission_date', type: 'desc'} }"
      :search-options="{
        enabled: true,
        externalQuery: search
      }"
      :row-style-class="rowStyleClass"
      max-height="calc(100vh - 151px)"
      :fixed-header="!currentBrowser.isExplorer"
      @on-row-mouseleave="onRowMouseleave"
      @on-cell-click="emitGoToProgram"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'name'">
          <p class="program-name">{{ props.row.name }}</p>
          <p class="program-type">{{ getProgramTypeName(props.row.type) }}</p>
        </span>
        <span v-else-if="props.column.field === 'admission_date'">
          {{ $toLocal(props.row.admission_date) }}
        </span>
        <span v-else-if="props.column.field === 'termination_date'">
          {{ terminationDate(props.row.termination_date) }} <span v-if="props.row.pre_discharged && !props.row.discharged"><br> Pending</span>
        </span>
        <span v-else-if="props.column.field == 'actions'">
          <sub-menu-dots
            v-if="props.row.actions.length !== 0"
            :ref="`dots-${props.row.vgt_id}`"
            :show-title="false"
            :menu-items="props.row.actions"
            @dischargeFromProgram="dischargeFromProgram(props.row)"
            @finalizeDischarge="finalizeDischarge(props.row)"
            @reverseAdmission="deleteDialog = props.row.access_control_program_id"
          />
          <delete-dialog
            v-if="deleteDialog === props.row.access_control_program_id"
            :key="props.row.access_control_program_id"
            :title="$t('reversePatientAdmition.title')"
            :body="$t('reversePatientAdmition.body')"
            :confirm-btn="$t('yesReverse')"
            :cancel-btn="$t('cancel')"
            @onDelete="onDelete(props.row)"
            @onCancel="onCancel"
          />
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <div slot="emptystate">
        {{ $t('noProgramsFound') }}
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import SearchInput from '@/components/common/SearchInput'
import DeleteDialog from '@/components/common/DeleteDialog'
import Overlay from '@/components/clinician/Overlay'
import { Helpers } from '@/mixins/Helpers'
import { PatientHelper } from '@/mixins/PatientHelper'
import { ClinicianPatientProgramsTable } from '@/mixins/TableColumnsHelper'
import SubMenuDots from '@/components/common/SubMenuDots'
import { mapGetters } from 'vuex'

export default {
  name: 'ClinicianPatientProgramsTable',
  components: {
    VueGoodTable,
    DeleteDialog,
    SearchInput,
    Overlay,
    SubMenuDots
  },
  mixins: [Helpers, DateTimeHelper, ClinicianPatientProgramsTable, PatientHelper],
  props: ['programs', 'programTypes'],
  data () {
    return {
      search: '',
      rowActive: null,
      deleteDialog: false,
      acpToFinalizeDischarge: null
    }
  },
  computed: {
    ...mapGetters({
      currentBrowser: 'getCurrentBrowser'
    }),
    tablePrograms () {
      return this.programs.map((acp) => {
        acp.actions = []
        if (!acp.pre_discharged) {
          acp.actions.push({ action: 'dischargeFromProgram', label: this.$t('dischargeFromProgram') })
        } else if (!acp.discharged) {
          acp.actions.push({ action: 'finalizeDischarge', label: this.$t('finalizeDischarge') })
        }
        if (acp.allow_undo_admission && !acp.pre_discharged && !acp.discharged) {
          acp.actions.push({ action: 'reverseAdmission', label: this.$t('reverseAdmission') })
        }
        return acp
      })
    },
    routematch () {
      return this.$route.name === 'ClinicianAddPatientToProgram' || this.$route.name === 'ClinicianPatientDischargeFromProgram'
    }
  },
  methods: {
    promptBeforeClose () {
      return new Promise((resolve) => {
        const promptOptions = {
          message: this.$t('overlayCancelAdd', { data: 'program' }),
          title: this.$t('areYouSureToExit'),
          okButton: this.$t('yesExit')
        }
        this.$promptBeforeAction(promptOptions, () => {
          resolve()
        })
      })
    },
    terminationDate (date) {
      return (date) ? this.$toLocal(date) : ''
    },
    resetActiveRow () {
      this.rowActive = null
    },
    rowStyleClass (row) {
      return this.rowActive === row.access_control_program_id ? 'program-row-active' : ''
    },
    onRowMouseleave (params) {
      const submenu = this.$refs[`dots-${params.row.vgt_id}`]
      if (submenu) {
        submenu.close()
      }
    },
    finalizeDischarge (acp) {
      this.acpToFinalizeDischarge = acp
      if (!acp.has_alerts_triggered && acp.pending_measures === 0) {
        return this.finalizeDischargeCallback()
      }
      const promptOptions = {
        message: acp.has_alerts_triggered ? this.$t('patientHasActiveAlerts') : this.$t('patientNotCompletedMeasures'),
        title: this.$t('areYouSureToFinalize'),
        okButton: this.$t('yesFinalize')
      }
      this.$promptBeforeAction(promptOptions, this.finalizeDischargeCallback)
    },
    finalizeDischargeCallback () {
      const acp = this.acpToFinalizeDischarge
      const programAssignation = this.activePatientProgramsAssignation.find(pa => parseInt(acp.access_control_program_id) === parseInt(pa.id))
      const params = {
        patient_id: acp.access_control_id,
        access_control_program_id: acp.access_control_program_id,
        date: programAssignation.discharge_date,
        measures: [],
        force: true,
        disposition_status: programAssignation.disposition_status,
        assignment_id: this.$getDecodedId(this.$route.params.ccaId)
      }
      this.$store.dispatch('DISCHARGE_FROM_PROGRAM', params).then(responseData => {
        this.$toast.success({ message: this.$t('dischargeFinalizedMessage') })

        // Would be ideal to do this generically OWL-6556 - Supervisor does not have access to patient data after discharge or team change.
        if (!responseData.hasOwnProperty('cancelActions') || !responseData.cancelActions) { // eslint-disable-line no-prototype-builtins
          this.$emit('refresh')
          this.$store.dispatch('RESET_RESPONDENT_LAST_SESSION')
          this.$updatePatientOverview()
        }
      }).catch(e => {
        this.$handleApiError(e)
      })
    },
    dischargeFromProgram (acp) {
      const params = {
        patientId: this.$getEncodedId(acp.access_control_id),
        accessControlProgramId: this.$getEncodedId(acp.access_control_program_id),
        assignmentId: this.$route.params.ccauId,
        dischargingFromSection: 2
      }
      this.$router.push({ name: 'ClinicianPatientDischargeFromProgram', params })
    },
    emitGoToProgram (params) {
      if (params.column.field === 'actions') {
        return
      }
      this.rowActive = params.row.access_control_program_id
      this.$emit('goToProgram', params.row)
    },
    getProgramTypeName (programTypeId) {
      const match = this.programTypes.find(type => Number(type.id) === Number(programTypeId))

      return match ? match.abbreviation : ''
    },
    onDelete (row) {
      const data = {
        access_control_id: row.access_control_id,
        access_control_program_id: row.access_control_program_id
      }
      this.$store.dispatch('DELETE_PROGRAM_ASSIGNATION', data).then((data) => {
        if (!data || !data.cancelActions) {
          this.$updatePatientOverview()
        }
      }).catch((e) => {
        this.$handleApiError(e)
      })
      this.$emit('deleted')
      this.deleteDialog = false
    },
    onCancel () {
      this.deleteDialog = false
    },
    closeOverlay () {
      this.$router.push({ name: 'ClinicianPatientPrograms', params: this.$route.params })
    }
  }
}
</script>

<style scoped>
  .program-name { font-weight: 700; margin-bottom: 0; }
  .program-type { margin-bottom: 0; position: relative; }
  .search { padding: 0px 20px 15px 3px; max-width: 366px; }
</style>
