<template>
  <div>
    <div class="patient-programs">
      <div class="row">
        <div class="col-lg-7 patient-programs-content">
          <ClinicianPatient-programs-table
            ref="programsTable"
            :programs="programs"
            :program-types="programTypes"
            @deleted="onProgramDeleted"
            @refresh="getData"
            @goToProgram="goToProgram"
          />
        </div>
        <div
          v-if="detailProgram"
          class="col-lg-5"
        >
          <div class="program-edit">
            <div class="title">
              {{ $t('detail') }}
            </div>
            <div>
              <h3 class="program-title">
                {{ detailProgram.full_name }}
              </h3>
              <clinician-add-patient-to-program
                :program="detailProgram"
                :patient-id="activeAssignment.user_id"
                @cancel="cancelEdit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClinicianPatientProgramsTable from '@/components/clinician/patients/programs/ClinicianPatientProgramsTable'
import ClinicianAddPatientToProgram from '@/components/clinician/patients/ClinicianAddPatientToProgram'

export default {
  name: 'ClinicianPatientPrograms',
  components: {
    ClinicianPatientProgramsTable,
    ClinicianAddPatientToProgram
  },
  data () {
    return {
      detailProgram: null
    }
  },
  computed: {
    ...mapGetters({
      activeAssignment: 'getActiveAssignment',
      patientPrograms: 'getSinglePatientPrograms',
      programTypes: 'getProgramTypes'
    }),
    programs () {
      return this.patientPrograms.map(program => {
        return {
          ...program.program,
          admission_date: program.admission_date,
          discharged: parseInt(program.discharged),
          pre_discharged: parseInt(program.pre_discharged),
          allow_undo_admission: program.allow_undo_admission,
          termination_date: program.termination_date,
          access_control_program_id: program.id,
          pending_measures: program.pending_measures,
          has_alerts_triggered: program.has_alerts_triggered,
          access_control_id: program.access_control_id
        }
      })
    }
  },
  watch: {
    '$route.name' (to, from) {
      if (from === 'ClinicianPatientDischargeFromProgram') {
        this.getData()
      }
    }
  },
  created () {
    this.getData()
  },
  beforeDestroy () {
    this.detailProgram = null
  },
  methods: {
    getData () {
      this.$store.dispatch('GET_PROGRAM_TYPES')
      if (this.activeAssignment.user_id) {
        const loader = this.$loading.show()
        this.$store.dispatch('GET_SINGLE_PATIENT_PROGRAMS', this.activeAssignment.user_id).catch(() => {
          this.$router.push({ name: 'PageNotFound' })
        }).finally(() => {
          loader.hide()
        })
      }
    },
    cancelEdit () {
      this.detailProgram = null
      this.$refs.programsTable.resetActiveRow()
    },
    goToProgram (program) {
      this.detailProgram = program
    },
    onProgramDeleted () {
      this.detailProgram = null
      this.$toast.success({ message: this.$t('admissionUndone') })
    }
  }
}
</script>

<style scoped>
  .patient-programs {
    padding-left: 15px;
  }
  .patient-programs-content {
    background-color: #FFF;
    padding: 20px 0 20px 15px;
    }
</style>
